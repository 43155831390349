
import { mapActions, mapState, storeToRefs } from 'pinia'
import startObserverLoading from '@/mixins/startObserverLoading.js'
import { INCLUDE_FIELDS } from '@/constants'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { useCustomer } from '@/stores/customer'

export default {
  name: 'MPLandingRecommended',
  fetchOnServer: true,
  isLoadingComponent: false,
  mixins: [startObserverLoading],
  props: {
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      customerStore,
      uiStore,
      resolution,
    }
  },
  data() {
    return {
      isRecommendedProductsVisible: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.getProducts()
      if (this.marketStore.products.length) {
        this.isRecommendedProductsVisible = true
      }
    }
  },

  computed: {
    ...mapState(useMarketStore, ['products', 'pagination']),
    limit() {
      let limit = 15
      if (this.resolution === 'lg') {
        limit = 12
      }
      if (this.resolution === 'md') {
        limit = 9
      }
      if (this.resolution === 'sm') {
        limit = 6
      }
      return limit
    },
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.MPLandingRecommended,
        this.showRecommendedProducts,
      )
    }
  },
  methods: {
    ...mapActions(useMarketStore, ['getProductsWithoutFilters']),
    async showRecommendedProducts() {
      await this.getProducts()
      if (this.marketStore.products.length) {
        this.isRecommendedProductsVisible = true
      }
    },
    async getProducts() {
      if (this.isLoadingComponent) return
      let per_page = this.limit
      if (this.$device.isCrawler) {
        per_page = 15
      }
      const options = {
        per_page,
        exclude_fields:
          'ratings.questions,ratings.assessment_id,ratings.product_id',
      }
      options['q[s]'] = 'global_popular_score desc'
      options['q[status_in]'] = 'active'
      options['q[response_mode]'] = 'products'
      options['q[market_order]'] = true
      options.include_fields = `${INCLUDE_FIELDS},categories,min_qty,default_offer_allow_qty,loyalty_cashback,product_labels,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,offers.seller_id,offers.seller_marketing_name,market_lottery_default,market_lottery_premium,offers.product_offer_labels,categories.path_ids`
      options.opaque_id = this.$route?.path
      try {
        this.isLoadingComponent = true
        const products = await this.getProductsWithoutFilters({ options })
        if (products && products.length === 0) {
          options['q[s]'] = 'id random'
          await this.getProductsWithoutFilters({ options })
        }
      } catch (e) {
        console.log('ERROR:MPLandingRecommended: getProductsWithoutFilters', e)
      }
    },
  },
}
