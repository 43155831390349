
import { useMarketStore } from '~/stores/market'
import { useCustomer } from '@/stores/customer'
import startObserverLoading from '@/mixins/startObserverLoading'

export default {
  name: 'TopSeoTags',
  mixins: [startObserverLoading],

  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    return {
      marketStore,
      customerStore,
    }
  },
  data() {
    return {
      showAll: false,
      waitingToLoad: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.startLoadingTags()
    }
  },
  computed: {
    tags() {
      if (this.$device.isMobile) {
        if (this.showAll) {
          return this.marketStore.mainTopSeoTags.filter(item => item.name)
        } else {
          return this.marketStore.mainTopSeoTags
            .filter(item => item.name)
            .slice(0, 15)
        }
      } else {
        return this.marketStore.mainTopSeoTags.filter(item => item.name)
      }
    },
    locale() {
      return this.$i18n?.locale
    },
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.landingTopSeoTags,
        this.startLoadingTags,
      )
    }
  },
  methods: {
    async startLoadingTags() {
      await this.marketStore.getMainTopSeoTags({
        'q[s]': 'random',
        'q[tag_type]': 'seo',
        'q[main_page]': true,
        per_page: 30,
        'q[opaque_id]': this.$route?.path,
      })

      this.waitingToLoad = false
    },
  },
}
